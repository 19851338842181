<template>
  <f7-page name="home">
    <f7-navbar :sliding="false">
      <f7-nav-left>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title sliding>RADIO H<i class="material-icons">mic</i>PS<span class="com">.com</span></f7-nav-title>
      <f7-nav-right>
        <f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>
      </f7-nav-right>
      <f7-subnavbar :inner="false">
        <f7-searchbar
        search-container=".radio-list"
        search-item="li"
        search-in=".item-title"
        :disable-button="!$theme.aurora"
        placeholder="Search Radio"
        ></f7-searchbar>
      </f7-subnavbar>
    </f7-navbar>

    <f7-list class="searchbar-not-found">
      <f7-list-item title="Radio not found"></f7-list-item>
    </f7-list>

    <div class="list radio-list">
      <ul>
        <li v-for="station in stations" @click="playRadio(station)">
          <a href="#" class="item-content">
            <div class="item-media">
              <img slot="media" :src="station.logo" width="44" />
            </div>
            <div class="item-inner">
              <div class="item-title">
                {{station.title}}
                <div class="item-footer">{{station.freq}}</div>
              </div>
              <div class="item-after">
                <i class="material-icons button-play">play_circle_outline</i>
                <div class="equalizer">
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                  <div class="bar"></div>
                </div>
              </div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </f7-page>
</template>

<script>
  import {Howl, Howler} from 'howler';

  export default {
    data: function () {
      return {
        stations: [],
        playNow: null,
      };
    },
    methods: {
      playRadio(e) {
        const self = this, app = self.$f7, $ = self.$$;

        $(document).on('click', 'li', function (e) {
          $('.button-play').show();
          $('.equalizer').hide();

          $(this).find('.button-play').hide();
          $(this).find('.equalizer').show();
        });

        if (self.playNow) {
          var playOn = self.stations.find(station => station.id == self.playNow);
          playOn.howl.unload();
        }

        self.playNow = e.id

        if (!e.howl) {
          e.howl = new Howl({
            src: e.src,
            html5: true,
            format: ['mp3', 'aac']
          });
        }

        e.howl.play();
      },
      pauseRadio() {
        const self = this, app = self.$f7, $ = self.$$;

        window.plugins.streamingMedia.stopAudio();
      },
    },
    mounted() {
      const self = this, app = self.$f7, $ = self.$$;

      app.request.json('https://api.radiohips.com', function (data) {
        self.stations = data;
      });
    },
  }
</script>

<style>
body {
  max-width: 500px;
  margin: auto;
  background: #eee;
}
.title {
  font-weight: bolder !important;
  font-size: xx-large !important;
  width: 100%;
  font-family: monospace;
}
.title.sliding {
  text-align: center;
}
.title.sliding .material-icons {
  vertical-align: middle;
}
span.com {
  font-size: x-small;
  display: none;
}
.right i.icon.material-icons {
  visibility: hidden;
}
</style>

<style>
a.item-content {
  color: inherit;
}
a.item-content .item-inner {
  min-height: 65px;
}
i.material-icons.button-play {
  padding-right: 15px;
}
.equalizer {
  padding-top: 15px;
  padding-right: 15px;
  display: none;
}
.equalizer .bar {
  position: relative;
  float: left;
  background-color: #0e81f5;
  height: 10px;
  margin-left: 1px;
  width: 3px;
  animation: jump 0ms -800ms linear infinite alternate;
}
@keyframes jump {
  0% {
    height: 10px;
    top: -5px;
  }
  100% {
    top: -15px;
    height: 30px;
  }
}
.equalizer .bar:nth-child(1) {
  animation-duration: 499ms;
}
.equalizer .bar:nth-child(2) {
  animation-duration: 486ms;
}
.equalizer .bar:nth-child(3) {
  animation-duration: 500ms;
}
.equalizer .bar:nth-child(4) {
  animation-duration: 437ms;
}
.equalizer .bar:nth-child(5) {
  animation-duration: 497ms;
}
.equalizer .bar:nth-child(6) {
  animation-duration: 481ms;
}
.equalizer .bar:nth-child(7) {
  animation-duration: 433ms;
}
.equalizer .bar:nth-child(8) {
  animation-duration: 493ms;
}
.equalizer .bar:nth-child(9) {
  animation-duration: 457ms;
}
.equalizer .bar:nth-child(10) {
  animation-duration: 457ms;
}
.equalizer .bar:nth-child(11) {
  animation-duration: 475ms;
}
.equalizer .bar:nth-child(12) {
  animation-duration: 462ms;
}
.equalizer .bar:nth-child(13) {
  animation-duration: 463ms;
}
.equalizer .bar:nth-child(14) {
  animation-duration: 438ms;
}
.equalizer .bar:nth-child(15) {
  animation-duration: 433ms;
}
.equalizer .bar:nth-child(16) {
  animation-duration: 461ms;
}
.equalizer .bar:nth-child(17) {
  animation-duration: 470ms;
}
.equalizer .bar:nth-child(18) {
  animation-duration: 438ms;
}
.equalizer .bar:nth-child(19) {
  animation-duration: 492ms;
}
.equalizer .bar:nth-child(20) {
  animation-duration: 490ms;
}
.equalizer.paused .bar {
  animation-play-state: paused;
}
</style>