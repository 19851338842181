<template>
<f7-app :params="f7params" >
  <!-- Status bar overlay for fullscreen mode-->
  <f7-statusbar></f7-statusbar>

  <!-- Left panel with cover effect when hidden -->
  <f7-panel left cover>
    <f7-view>
      <f7-page>
        <!-- <f7-navbar title="RADIO HIPS"></f7-navbar> -->
        <div class="navbar">
          <div class="navbar-inner sliding">
            <div class="title">RADIO H<i class="material-icons">mic</i>PS</div>
          </div>
        </div>
        <!-- <f7-block-title>Left View Navigation</f7-block-title>
        <f7-list>
          <f7-list-item link="/left-page-1/" title="Left Page 1"></f7-list-item>
          <f7-list-item link="/left-page-2/" title="Left Page 2"></f7-list-item>
        </f7-list> -->
        <!-- <f7-block-title>Control Main View</f7-block-title> -->
        <f7-block-title>Social Media</f7-block-title>
        <f7-list>
          <f7-list-item external link="http://instagram.com/radiohips" panel-close title="Instagram"></f7-list-item>
          <f7-list-item external link="https://www.facebook.com/Radio-Hips-106037327429365" panel-close title="Facebook"></f7-list-item>
          <f7-list-item external link="http://twitter.com/hipsradio" panel-close title="Twitter"></f7-list-item>
        </f7-list>
        <f7-list>
          <f7-list-item link="/about/" view=".view-main" panel-close title="About Us"></f7-list-item>
          <!-- <f7-list-item link="/form/" view=".view-main" panel-close title="Form"></f7-list-item> -->
          <!-- <f7-list-item link="#" view=".view-main" back panel-close title="Back in history"></f7-list-item> -->
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Right panel with reveal effect-->
  <f7-panel right reveal theme-dark>
    <f7-view>
      <f7-page>
        <f7-navbar title="Right Panel"></f7-navbar>
        <f7-block>Right panel content goes here</f7-block>
      </f7-page>
    </f7-view>
  </f7-panel>


  <!-- Your main view, should have "view-main" class -->
  <f7-view main class="safe-areas" url="/"></f7-view>


  <!-- Popup -->
  <f7-popup id="my-popup">
    <f7-view>
      <f7-page>
        <f7-navbar title="Popup">
          <f7-nav-right>
            <f7-link popup-close>Close</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block>
          <p>Popup content goes here.</p>
        </f7-block>
      </f7-page>
    </f7-view>
  </f7-popup>

  <f7-login-screen id="my-login-screen">
    <f7-view>
      <f7-page login-screen>
        <f7-login-screen-title>Login</f7-login-screen-title>
        <f7-list form>
          <f7-list-input
            type="text"
            name="username"
            placeholder="Your username"
            :value="username"
            @input="username = $event.target.value"
          ></f7-list-input>
          <f7-list-input
            type="password"
            name="password"
            placeholder="Your password"
            :value="password"
            @input="password = $event.target.value"
          ></f7-list-input>
        </f7-list>
        <f7-list>
          <f7-list-button title="Sign In" login-screen-close @click="alertLoginData"></f7-list-button>
          <f7-block-footer>
            Some text about login information.<br>Click "Sign In" to close Login Screen
          </f7-block-footer>
        </f7-list>
      </f7-page>
    </f7-view>
  </f7-login-screen>
</f7-app>
</template>
<script>
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes.js';

  export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          id: 'com.radiohips', // App bundle ID
          name: 'Radio Hips', // App name
          theme: 'md', // Automatic theme detection
          // App root data
          data: function () {
            return {
              user: {
                firstName: 'John',
                lastName: 'Doe',
              },

            };
          },

          // App routes
          routes: routes,
          // Enable panel left visibility breakpoint
          panel: {
            leftBreakpoint: 960,
          },

          // Register service worker
          serviceWorker: this.$device.cordova ? {} : {
            path: '/service-worker.js',
          },
          // Input settings
          input: {
            scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
            scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            overlay: false,
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
            androidBackgroundColor: '#000000',
            androidTextColor: 'white',
          },
        },

        // Login screen data
        username: '',
        password: '',
      }
    },
    methods: {
      alertLoginData() {
        this.$f7.dialog.alert('Username: ' + this.username + '<br>Password: ' + this.password);
      },
      onBackKeyDown: function() {
        const self = this, app = self.$f7, $ = self.$$;

        var mainView = self.$f7.views.main;
        var leftp = app.panel.left && app.panel.left.opened;
        var rightp = app.panel.right && app.panel.right.opened;

        if (leftp || rightp) {
          app.panel.close();
          return false;
        } else if ($('.modal-in').length > 0) {
          app.dialog.close();
          app.popup.close();
          return false;
        } else if (mainView.router.url == '/') {
          app.dialog.confirm('Yakin ingin keluar Aplikasi?', 'Keluar Aplikasi', function() {
            navigator.app.exitApp();
          }, (event) => {});
        }else{
          mainView.router.back();
        }
      }
    },
    mounted() {
      const self = this, app = self.$f7, $ = self.$$;

      document.addEventListener("backbutton", self.onBackKeyDown, false);

      let evt = new Event('backbutton');
      $('.backBtn').on('click', function() {
        document.dispatchEvent(evt)
      })
      
      this.$f7ready((f7) => {
        // Init cordova APIs (see cordova-app.js)
        if (f7.device.cordova) {
          cordovaApp.init(f7);
        }
        // Call F7 APIs here
      });
    }
  }
</script>