<template>
  <f7-page name="about">
    <f7-navbar title="About Us" back-link="Back"></f7-navbar>
    <f7-list>
      <f7-list-item header="Develop By" :title="profile.name"></f7-list-item>
      <f7-list-item header="Email" :title="profile.email" external link="mailto:radiohips@gmail.com"></f7-list-item>
      <f7-list-item header="Website" :title="profile.website" external link="https://radiohips.com"></f7-list-item>
    </f7-list>
  </f7-page>
</template>

<script>
  export default {
    data() {
      return {
        profile: {
          name: 'Eko Syamsudin',
          email: 'radiohips@gmail.com',
          website: 'www.radiohips.com',
        }
      }
    },
  };
</script>